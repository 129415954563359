<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="action-buttons">
            <v-btn
              dark
              color="secondary"
              class="ma-2"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-btn
              color="primary"
              :loading="getFranchisesLoading"
              :disabled="getFranchisesLoading"
              @click="save"
            >
              Save Changes
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title class="pl-6">Franchise Customers</v-card-title>
        <div class="select-container">
          <div>
            <h6>Available Customers</h6>
            <select
              multiple
              v-model="availableSelectedCustomers"
              @dblclick="assignCustomer"
            >
              <option v-for="user in availableCustomerOptions" :value="user.id">
                {{ user.description }}
              </option>
            </select>
          </div>
          <div class="middle">
            <v-btn @click="assignCustomer" rounded text x-large>
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
            <v-btn @click="removeCustomer" rounded text x-large>
              <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>
          </div>
          <div>
            <h6>Assigned Franchise Customers</h6>
            <select
              multiple
              v-model="currentSelectedCustomers"
              @dblclick="removeCustomer"
            >
              <option v-for="user in currentCustomers" :value="user.id">
                {{ user.description }}
              </option>
            </select>
          </div>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { getCustomerOptions } from "@/services/franchiseService";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Franchise",
  },
  data() {
    return {
      id: _.get(this.$route, "params.id"),
      availableSelectedCustomers: [],
      availableCustomers: [],
      currentSelectedCustomers: [],
      currentCustomers: [],
    };
  },
  mounted: async function () {
    try {
      const { data } = await getCustomerOptions(this.id);
      this.currentCustomers = _.get(data, "customers");
      this.availableCustomers = _.get(data, "available");
    } catch (error) {
      console.log("🚀 ~ error:", error);
    }
  },
  methods: {
    ...mapActions(["updateFranchiseCustomers"]),
    assignCustomer() {
      if (!this.availableSelectedCustomers.length) {
        return;
      }

      this.currentCustomers = [
        ...this.currentCustomers,
        ..._.filter(this.availableCustomers, (customer) =>
          this.availableSelectedCustomers.includes(customer.id)
        ),
      ];

      this.availableCustomers = _.filter(
        this.availableCustomers,
        (customer) => !this.availableSelectedCustomers.includes(customer.id)
      );

      this.availableSelectedCustomers = [];
    },
    removeCustomer() {
      if (!this.currentSelectedCustomers.length) {
        return;
      }

      this.availableCustomers = [
        ...this.availableCustomers,
        ..._.filter(this.currentCustomers, (customer) =>
          this.currentSelectedCustomers.includes(customer.id)
        ),
      ];

      this.currentCustomers = _.filter(
        this.currentCustomers,
        (customer) => !this.currentSelectedCustomers.includes(customer.id)
      );

      this.currentSelectedCustomers = [];
    },
    async save() {
      const result = await this.updateFranchiseCustomers({
        id: this.id,
        payload: { customers: _.map(this.currentCustomers, "id") },
      });

      if (_.get(result, "error")) {
        this.$store.dispatch("showSnackbar", "Something went wrong.");

        return;
      }

      this.$store.dispatch("showSnackbar", result);
      this.$router.push("/app/franchise");
    },
  },
  computed: {
    ...mapGetters(["getFranchisesLoading"]),
    availableCustomerOptions() {
      return _.sortBy(this.availableCustomers, [
        (customer) => customer.description,
      ]);
    },
  },
};
</script>
<style lang="scss" scoped>
.action-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-container {
  display: grid;
  grid-template-columns: 35% 15% 35%;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;

  h6 {
    text-align: center;
  }

  select {
    height: 200px;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;

    option {
      padding: 4px 10px;
    }
  }

  .middle {
    align-self: center;
    text-align: center;
  }
}
</style>
